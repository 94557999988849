module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Handelsträdgårdar","short_name":"Handelsträdgårdar","start_url":"/","background_color":"#efefef","theme_color":"#efefef","display":"minimal-ui","icon":"src/images/handelstradgardar/logo.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"2787e6e414309fb72cf6a3949c4a6a05"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-WHY7FMRN3V"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"path":"/home/vsts/work/1/s/locales","languages":["sv"],"defaultLanguage":"sv","i18nextOptions":{"debug":true}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
